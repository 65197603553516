<!--  -->
<template>
  <div class='Backstage'>
    <div class="leftcard">
      <div class="flex items-center" style="margin-left: 20px;margin-top: 17px;">
        <img src="https://cdn.fengwork.com/workbench/Group_101.png" alt="" width="22px" height="24px">
        <span style="margin-left: 5px;font-size: 13px;color: #1b1b1b;font-weight: 550;">管理后台</span>
      </div>
      <div style="margin-top: 16px;margin-left: 12px;">
        <div class="leftBtn" @click="switchRouter(1)">用户组管理</div>
        <div class="leftBtn activeLeftBtn" @click="switchRouter(2)">流程管理</div>
      </div>
    </div>
    <div class="middlecard">
      <div class="midTop">
        <div>流程分组</div>
        <img src="@/assets/Group_102.png" alt="" width="20px" height="20px" style="cursor: pointer;"
          @click="addGroupInput">
      </div>
      <div class="midList">
        <div
          :class="(!visible) && midId == item.id ? 'activeVisibleItem' : midId == item.id ? 'midListItem activeMidItem' : 'midListItem'"
          v-for="(item, index) in groupList" :key="item.id" @click="handleClick(item)">
          <div style="width: 98px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left;"
            v-if="visible || midId !== item.id">
            {{ item.name }}
          </div>
          <div style="width: 98px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left;"
            v-if="midId == item.id && (!visible)">
            <input type="text" @keydown.enter="(e) => inputKeyEnter(e, item)" @blur="inputBlur" v-model="item.name"
              autofocus ref="myInput">
          </div>
          <div class="btns" style="height: 12px;">
            <img src="@/assets/pen.png" alt="" width="12px" height="12px" style="margin-right: 6px;"
              @click.stop="editFlowGroup(item)" v-if="visible">
            <img src="@/assets/times.png" alt="" width="12px" height="12px" @click.stop="deleteFlowDialog(item.id, 0)"
              v-if="visible">
          </div>
        </div>
        <div class="activeVisibleItem" v-if="createdGroup">
          <div style="width: 98px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left;">
            <input type="text" @keydown.enter="(e) => createKeyEnter(e)" @blur="inputBlur" v-model="addname" autofocus
              ref="myAddInput">
          </div>
          <div class="btns" style="height: 12px;">
            <img src="@/assets/times.png" alt="" width="12px" height="12px" @click.stop="deleteAddInput()">
          </div>

        </div>
        <!-- <div class="midListItem activeMidItem">
          <div style="width: 98px;white-space: nowrap;overflow: hidden;text-overflow: ellipsis;text-align: left;">人事管理
          </div>
          <div class="btns" style="height: 12px;">
            <img src="@/assets/pen.png" alt="" width="12px" height="12px" style="margin-right: 6px;">
            <img src="@/assets/times.png" alt="" width="12px" height="12px">
          </div>
        </div> -->
      </div>

    </div>
    <div class="maincard">
      <div class="topL">
        <div class="inputDiv">
          <i class="el-icon-search"></i>
          <input type="text" v-model="searchValue" placeholder="搜索流程名称" @keydown.enter="searchFlow">
        </div>
        <div class="caogaoBtn" @click="showDraft()"><img
            style="width: 14px;height: 14px;margin-left: 8px;margin-right: 2px;" src="@/assets/copy.png" alt="">草稿箱
        </div>
        <div class="addBtn" @click="openCreate"><i class="el-icon-plus ml-2" style="margin-right: 2px;"></i>创建流程</div>
      </div>
      <div class="bootn">
        <div class="culm">
          <el-row style="flex:1">
            <el-col class="cumItem" :span="10">流程名称</el-col>
            <el-col class="cumItem" :span="11">发起范围</el-col>
            <el-col class="cumItem" :span="3">状态</el-col>
          </el-row>
          <div class="cumItem" style="flex-shrink: 0;width: 90px;">操作</div>
        </div>
        <div class="clist">
          <div v-for="(item, index) in groupFlowList" :index="item.id">
            <div style="display: flex;align-items: center;position: relative;">
              <el-row class="cl">
                <el-col class="cumItem flex items-center" :span="10">
                  <div
                    style="border-radius: 50%;width: 28px;height: 28px;margin:0 8px 0 12px;flex-shrink: 0;overflow: hidden;">
                    <img
                      :src="item.icon == 'https://cdn.fengwork.com/sosee_oa/20240119icon.png' ? 'https://cdn.fengwork.com/workbench/Group_1000005531.png' : item.icon"
                      alt="" style="width: 100%;height: 100%;">
                  </div>
                  <div class="clText" style="width: calc(100% - 48px);word-break: break-all;text-align: left;">
                    {{ item.name }}</div>
                </el-col>
                <el-col :span="11">
                  <span class="clTextspan" style="width: 100%;word-break: break-all;">{{ !item.num ? (item.scopeList ||
                    '') : (item.scopeList + '...')
                    }}</span></el-col>
                <el-col :span="3">
                  <el-switch v-model="item.isVisible" :active-value="0" :inactive-value="1" active-color="#5669ec"
                    inactive-color="#5669ec1a" @change="(e) => changeSwitch(e, item.id)">
                  </el-switch>

                </el-col>
              </el-row>
              <!-- <div style="flex-shrink: 0;width: 50px;">
                
              </div> -->
              <div style="flex-shrink: 0;width: 90px;font-size: 13px;justify-content: space-around;"
                class="flex items-center">
                <div style="cursor: pointer;width: 16px;height: 16px;" class="imgIcon" @click="editGrouping(item.id)">
                  <img src="@/assets/pen-line.png" alt="" title="编辑" style="width: 100%;height: 100%;">
                </div>
                <div style="cursor: pointer;width: 16px;height: 16px;" class="imgIcon" @click="handleRemove(item)"><img
                    src="@/assets/folder-export.png" alt="" title="移动至" style="width: 100%;height: 100%;"></div>
                <div style="cursor: pointer;width: 16px;height: 16px;" class="imgIcon"
                  @click="deleteFlowDialog(item.id, 1)"><img src="@/assets/trash.png" alt="" title="删除"
                    style="width: 100%;height: 100%;"></div>
              </div>
            </div>
            <div class="line"></div>
          </div>
        </div>
      </div>
    </div>
    <transition name="el-fade-in">
      <Customdialog v-if='draftDialog' :dialogWidth="'60%'" :title="'草稿箱'" :dialogHeight="'60%'"
        @closeDialog="clickDraft">
        <template slot="dialogMain">
          <el-row style="text-align: left;font-size: 14px;color: #848484;border-bottom: 1px solid #f3f4f6;">
            <el-col :span="10" style="padding-left: 24px;padding-bottom: 11px;">流程名</el-col>
            <el-col :span="8">更新时间</el-col>
            <el-col :span="6" style="text-align: center;">操作</el-col>
          </el-row>
          <div v-for="(item, index) in draftFlowList" :key="item.id">
            <el-row
              style="text-align: left;font-size: 13px;color: #1b1b1b;height: 46px;line-height: 46px;border-bottom: 1px solid #f3f4f6;">
              <el-col :span="10" style="padding-left: 24px;">
                {{ item.name }}
              </el-col>
              <el-col :span="8">{{ $moment(item.updateTime).format('YYYY-MM-DD hh:mm:ss') }}</el-col>
              <el-col :span="6" style="display: flex;justify-content: space-around;align-items: center;">
                <div style="display: flex;align-items: center;justify-content: center;cursor: pointer;"
                  @click="editGrouping(item.id)"><img src="@/assets/pen2.png" alt="" width="14px" height="14px">
                  <div style="margin-left: 4px;color: #5669ec;">编辑</div>
                </div>
                <div style="display: flex;align-items: center;justify-content: center;cursor: pointer;"
                  @click="deleteFlow(item.id)"><img src="@/assets/trash.png" alt="" width="14px" height="14px">
                  <div style="margin-left: 4px;color: #f83e45;">删除</div>
                </div>
              </el-col>
            </el-row>
          </div>
          <div v-if="draftFlowList.length == 0">
            <div style="text-align: center;color: #848484;padding: 24px 0;font-size: 13px;">暂无数据</div>
          </div>
        </template>
      </Customdialog>
    </transition>
    <transition name="el-fade-in">
      <Customdialog v-if='smallDialog' :dialogWidth="'320px'" :title="'提示'" :dialogHeight="'140px'" :isFooter="true"
        @closeDialog="smallDialog = false" @submitDialog="deleteFlowIndex">
        <template slot="dialogMain">
          是否确认删除?
        </template>
      </Customdialog>
    </transition>
    <transition name="el-fade-in">
      <Customdialog v-if='removeDialog' :dialogWidth="'30%'" :title="'移动至'" :dialogHeight="'60%'" :isFooter="true"
        @closeDialog="clickRemove" @submitDialog="submitRemove">
        <template slot="dialogMain">
          <div class="gropList">
            <div v-for="(item, index) in removeGroupList" :class="acItemId == item.id ? 'acGroupItem' : 'groupItem'"
              @click="checkGroup(item)">
              {{ item.name }}
            </div>
          </div>
        </template>
      </Customdialog>
    </transition>
    <transition name="el-fade-in">
      <Customdialog v-if='createFlowVisible' :dialogWidth="'300px'" :title="'创建流程'" :dialogHeight="'200px'"
        :isFooter="false" @closeDialog="clickCreateVisible">
        <template slot="dialogMain">
          <div style="padding: 0 16px;">
            <div class="createBtn" style="margin-bottom: 8px;" @click="createNewFlow">
              创建新流程
            </div>
            <div class="createBtn" @click="createOldFlow">
              选择已有流程
            </div>
          </div>
        </template>
      </Customdialog>
    </transition>
    <transition name="el-fade-in">
      <Customdialog v-if='oldVisible' :dialogWidth="'300px'" :title="'创建流程'" :dialogHeight="'500px'" :isFooter="false"
        @closeDialog="clickCreateVisible" @submitDialog="submitRemove">
        <template slot="dialogMain">
          <div style="">
            <div class="mb16" style="padding: 0 20px">
              <el-input v-model="flowName" @input="getDepUserList" placeholder="请输入流程名称" clearable>
                ></el-input>
            </div>
            <div class="forms_list">
              <div class="forms_item" v-for="item in flowList" :key="item.id" @click="selectFlow(item)">
                {{ item.name }}
              </div>
            </div>
          </div>
        </template>
      </Customdialog>
    </transition>
  </div>
</template>

<script>
import { findGroup, findGroupList, flowManagementVisible, deleteGroup, editGroup, addGroup, getDraftList, moveFlowManagement, deleteFlow } from '@/api/back'
import { create } from 'lodash-es';
import Customdialog from '@/components/customdialog'
import { getDepUserList } from "@/api/flow.js";

export default {
  name: 'Backstage',
  components: { Customdialog },
  data() {
    return {
      value: true,
      searchValue: '',
      smallDialog: false,
      smallDialogId: '',
      smallDialogIndex: '',
      oldVisible: false,
      groupList: [],//分组列表
      groupFlowList: [],//流程列表
      midId: '',//当前选中的分组id
      visible: true,//分组列表是否显示
      dialogVisible: false,//弹窗
      createdGroup: false,//新建分组
      addname: '',//新建分组名称
      draftDialog: false,//草稿箱弹窗
      draftFlowList: [],//草稿箱列表
      removeDialog: false,//移动流程弹窗
      removeGroupList: [],//移动流程的分组列表
      acItemId: '',//选中移动的流程组id
      removeId: '',//选中移动的流程id
      createFlowVisible: false,
      flowList: []


    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  filters: {},

  methods: {
    async getDepUserList() {
      const { data } = await getDepUserList({ name: this.flowName });
      this.flowList = data;
    },

    handleClick(item) {

      if (this.midId !== item.id) {
        this.visible = true
      }
      this.midId = item.id
      this.findGroupList()
    },
    findGroup() {
      findGroup().then(res => {
        this.groupList = res.data
        if (this.groupList.length > 0) {
          this.handleClick(this.groupList[0])
        } else {
          this.groupFlowList = []
        }
      })
    },
    async findGroupList() {
      let obj = {
        groupId: this.midId,
        name: this.searchValue
      }
      const { data } = await findGroupList(obj);
      if (data) {
        data.forEach((e) => {
          let arr = [
            ...e.scope.dept,
            ...e.scope.uid,
            ...e.scope.group,
            ...e.scope.role,
          ].map((item) => item.name);
          e.num = arr.length > 2 ? true : false;
          if (arr.length > 2) {
            e.scopeList = arr.slice(0, 2).join(",");
          } else {
            e.scopeList = arr.join(",");
          }
        });
        this.groupFlowList = data;
      } else {
        this.groupFlowList = [];
      }
    },
    async changeSwitch(state, id) {
      const { data } = await flowManagementVisible(id, state);
      this.$message.success("修改成功");
    },
    searchFlow() {
      this.findGroupList()
    },
    editFlowGroup(item) {
      this.visible = !this.visible
      this.$nextTick(() => {
        if (this.$refs.myInput[0]) {
          this.$refs.myInput[0].focus()
        }
      })
    },
    addGroupInput() {
      this.createdGroup = true
      this.addname = ''
      this.$nextTick(() => {
        console.log(`output->`, this.$refs.myAddInput)
        // if (this.$refs.myAddInput[0]) {
        this.$refs.myAddInput.focus()
        // }
      })
    },
    deleteAddInput() {
      this.createdGroup = false
      this.addname = ''
    },
    // 删除分组
    async deleteGroup(id) {
      if (!id) {
        id = this.smallDialogId
      }
      const { code, data, msg } = await deleteGroup(id);
      if (code == 200) {
        this.$message.success("删除成功");
        this.findGroup()
      } else {
        this.$message.error(msg);
      }
      this.smallDialog = false
    },
    inputKeyEnter(val, item) {
      console.log(`output->`, val, item)
      let obj = {
        id: item.id,
        name: item.name
      }
      editGroup(obj).then(res => {
        if (res.code == 200) {
          this.$message.success("修改成功");
          this.visible = true
        } else {
          this.$message.error(res.msg);
        }
      })
    },
    createKeyEnter() {
      let obj = {
        name: this.addname
      }
      addGroup(obj).then(res => {
        if (res.code == 200) {
          this.$message.success("添加成功");
          this.addname = ''
          this.createdGroup = false
          this.findGroup()
        } else {
          this.$message.error(res.msg);
        }
      })

    },
    inputBlur() {
      // this.visible = true
    },
    switchRouter(i) {
      if (i == 1) {
        this.$router.push('/main/workbench/ProcessSetting/usergroup')
      }
    },
    addBtn() {
      this.dialogVisible = true
    },
    // 草稿箱
    async showDraft() {
      this.draftDialog = true
      let { data } = await getDraftList()
      this.draftFlowList = data

    },
    // 关闭草稿箱
    clickDraft() {
      this.draftDialog = false

    },
    // 移动流程
    handleRemove(item) {
      this.removeId = item.id
      this.removeGroupList = this.groupList.filter(i => i.id !== item.groupId)
      this.removeDialog = true
    },
    // 取消移动
    clickRemove() {
      this.acItemId = ''
      this.removeDialog = false

    },
    // 移动流程
    submitRemove() {
      if (this.acItemId && this.removeId) {
        moveFlowManagement(this.removeId, this.acItemId).then(res => {
          if (res.code == 200) {
            this.$message.success("移动成功")
            this.findGroupList()
            this.removeDialog = false
            this.acItemId = ''
          } else {
            this.$message.error(res.msg)
          }
        })

      } else {
        this.$message.error("请选择分组")
      }

    },
    // 选择移动分组
    checkGroup(item) {
      this.acItemId = item.id
      console.log(`output->item`, item)

    },
    deleteFlowDialog(id, index) {

      this.smallDialog = true
      this.smallDialogId = id
      this.smallDialogIndex = index
    },
    deleteFlowIndex() {
      if (this.smallDialogIndex == 0) {
        this.deleteGroup(this.smallDialogId)
      } else if (this.smallDialogIndex == 1) {
        this.deleteFlow(this.smallDialogId)
      }
    },



    // 删除流程
    async deleteFlow(id) {
      if (!id) {
        id = this.smallDialogId
      }
      const { data } = await deleteFlow(id);
      this.smallDialog = false
      this.$message.success("删除成功");
      this.findGroupList();
      if (this.draftDialog) {
        this.showDraft()
      }

    },
    editGrouping(flowId) {
      this.$router.push({
        name: "CreateFlow",
        query: {
          flowId
        },
      });

    },
    // 跳转创建
    openCreate() {
      if (!this.groupList.length) {
        this.$message.warning("请先添加审批流程分组");
        return;
      }
      this.createFlowVisible = true;
    },
    clickCreateVisible() {
      this.createFlowVisible = false;
      this.oldVisible = false;
    },
    createNewFlow() {
      this.createFlowVisible = false;

      this.$router.push({
        name: "CreateFlow",
        query: {
          id: this.midId,
        },
      });
    },
    createOldFlow() {
      this.createFlowVisible = false;
      this.getDepUserList()
      this.oldVisible = true;
    },
    selectFlow(i) {
      this.oldVisible = false;
      this.$router.push({
        name: "CreateFlow",
        query: {
          id: this.midId,
          flowId: i.id
        },
      });

    }
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created() {

  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {
    this.findGroup()
  },
  beforeCreate() { },
  beforeMount() { },
  beforeUpdate() { },
  updated() { },
  beforeDestroy() { },
  destroyed() { },
  //如果页面有keep-alive缓存功能，这个函数会触发
  activated() {
    this.findGroupList()

  },
}
</script>
<style></style>
<style scoped lang="scss">
.Backstage {
  padding: 16px;
  height: calc(100% - 32px);
  display: flex;

  .createBtn {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #f5f5f7;
    border-radius: 8px;
    cursor: pointer;
  }

  .createBtn:hover {
    border: 1px solid #5669ec;
    color: #5669ec;

  }

  .forms_item {
    width: 256px;
    margin: 4px auto;
    border: 1px solid #f5f5f7;
    border-radius: 8px;
    height: 24px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .forms_item:hover {
    background-color: #f5f5f7;

  }

  .line {
    height: 1px;
    border-top: 1px solid #ececec;
    margin-left: 40px;
    margin-right: 16px;
  }

  .leftcard {
    height: 100%;
    width: 150px;
    border-radius: 6px;
    background: #ffffff;
    margin-right: 8px;

    .leftBtn {
      width: 126px;
      height: 32px;
      border-radius: 4px;
      color: #727272;
      font-size: 13px;
      display: flex;
      align-items: center;
      justify-content: center;
      margin-bottom: 4px;
      cursor: pointer;
    }

    .leftBtn:hover {
      background: #f3f4f663;

    }

    .activeLeftBtn {
      color: #1b1b1b;
      background: #f3f4f6;
    }
  }

  .middlecard::-webkit-scrollbar {
    display: none;
  }

  .middlecard {
    height: 100%;
    width: 184px;
    border-radius: 6px;
    background: #ffffff;
    margin-right: 8px;
    padding: 21px 12px;
    overflow: auto;


    .midTop {
      display: flex;
      margin: 0 4px 13px;
      align-items: center;
      justify-content: space-between;
      font-size: 14px;
      color: #1b1b1b;
      font-weight: 550;

    }

    .midListItem {
      color: #727272;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      height: 32px;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 10px;
      margin-bottom: 4px;
      cursor: pointer;

      .btns {
        display: none;
      }

      input {
        -web-kit-appearance: none;
        -moz-appearance: none;
        outline: 0;
        border: none;
        height: 100%;
        box-sizing: border-box;
        padding-left: 1px;
        width: 100%;
        background: #f3f4f6;
        font-size: 13px;
        caret-color: #5669ec;
      }
    }

    .midListItem:hover {
      background: #f3f4f663;
    }

    .activeMidItem:hover {
      background: #f3f4f6;
    }

    .activeVisibleItem {
      color: #5669ec;
      background: #dce0fc;
      font-size: 14px;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 160px;
      height: 32px;
      border-radius: 4px;
      padding-left: 16px;
      padding-right: 10px;
      margin-bottom: 4px;
      cursor: pointer;

      input {
        -web-kit-appearance: none;
        -moz-appearance: none;
        outline: 0;
        border: none;
        height: 100%;
        box-sizing: border-box;
        padding-left: 1px;
        width: 100%;
        background: #dce0fc;
        font-size: 13px;
        color: #5669ec;
        caret-color: #5669ec;
      }
    }

    .activeMidItem {
      color: #1b1b1b;
      background: #f3f4f6;

      .btns {
        display: block;
      }
    }

  }

  .maincard {
    flex: 1;

    .bootn {
      background: #f3f4f6;
      height: calc(100% - 42px);
      border-radius: 6px 0px 0px 6px;
      overflow: hidden;
      display: flex;
      flex-direction: column;

      .culm {
        height: 40px;
        background-color: #5669ec;
        flex-shrink: 0;
        display: flex;

        .cumItem {
          color: #fafbfc;
          font-size: 13px;
          line-height: 40px;
        }
      }

      .clist {
        overflow: auto;

        .cl {
          height: 59px;
          display: flex;
          align-items: center;
          flex: 1;

          .clText {
            font-size: 13px;
            color: #1b1b1b;
            align-items: center;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /*省略几行写几*/
            -webkit-box-orient: vertical;

          }

          .clTextspan {
            font-size: 12px;
            color: #1b1b1b;
            overflow: hidden;
            text-overflow: ellipsis;
            display: -webkit-box;
            -webkit-line-clamp: 3;
            /*省略几行写几*/
            -webkit-box-orient: vertical;

          }
        }

        .imgIcon {}

        .imgIcon:hover {}
      }
    }

    .topL {
      display: flex;
      margin-bottom: 8px;

      .addBtn {
        width: 84px;
        height: 34px;
        border-radius: 6px;
        background: #5669ec;
        line-height: 34px;
        font-size: 12px;
        color: #fff;
        margin-left: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;
      }

      .caogaoBtn {
        width: 72px;
        height: 34px;
        border-radius: 6px;
        background: #f3f4f6;
        line-height: 34px;
        font-size: 12px;
        color: #848484;
        margin-left: 8px;
        cursor: pointer;
        display: flex;
        align-items: center;

      }

      .inputDiv {
        flex: 1;
        height: 34px;
        background: #f3f4f6;
        border-radius: 6px;
        display: flex;
        align-items: center;
        color: #848484;
        padding: 10px;
        font-size: 12px;

        input {
          -web-kit-appearance: none;
          -moz-appearance: none;
          outline: 0;
          border: none;
          height: 100%;
          background: #f3f4f6;
          box-sizing: border-box;
          font-size: 12px;
          color: #848484;
          border-radius: 6px;
          margin-left: 5px;
          padding-left: 1px;
          width: 130px;
        }

      }

    }

  }

  .gropList {
    margin: 0 24px;
    text-align: left;

    .groupItem {
      height: 32px;
      margin-bottom: 12px;
      // background-color: #f3f4f6;
      width: 100%;
      color: #848484;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }

    .acGroupItem {
      background-color: #dce0fc;
      height: 32px;
      margin-bottom: 12px;

      width: 100%;
      color: #848484;
      cursor: pointer;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 13px;
    }

    .groupItem:hover {
      background-color: #f3f4f663;
    }
  }




}
</style>
